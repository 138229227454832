body{
    min-height: 100vh;
    display: grid;
    place-items: center;
    justify-self: center;
  }
  
  .slider {
    height: 250px;
    margin: auto;
    position: relative;
    width: auto;
    display: grid;
    place-items: center;
    overflow: hidden;
  }
  
  .slide-track{
    display: flex;
    width: calc(650px * 15);
    animation: scroll 40s linear infinite;
  }
  
  .slide-track:hover{
    animation-play-state: paused;
  }
  
  @keyframes scroll {
    0%{
      transform: translateX(0);
  
    }
    100%{
      transform: translateX(calc(-250px * 9));
    }
  
  }
  
  .slide{
    height: 200px;
    width: 900px;
    display: flex;
    align-items: center;
    padding: 20px;
    perspective: 100px;
  }
  
  img{
    transform: transform 1s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    /* border-radius: 100px; */
  }
  
  img:hover{
    transform: translateZ(20px);
  }
  
  .slider::before,
  .slider::after{
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,
     rgba(255,255,255,0) 100%);
     content: '';
     height: 100%;
     position: absolute;
     width: 15px;
     z-index: 2;
  }
  
  .slider::before{
    left: 0;
    top: 0;
  }
  
  .slider::after{
    right: 0;
    top: 0;
    transform: rotate(180deg);
  }