.bodys{
  min-height: 100vh;
  display: grid;
  place-items: center;
  justify-self: center;
  background-color: whitesmoke;
}

.liders {
  /* height: 100px; */
  margin-top: -90px;
  position: relative;
  /* width: 1400px; */
  display: grid;
  place-items: center;
  overflow: hidden;
  justify-content: space-between;
}

.tracks {
  display: flex;
  /* width: 100%; */
  width: calc(250px * 18);
  animation: scroll-right 40s linear infinite;
  object-fit: cover;
  height: fit-content;
  cursor: pointer;
}

.slides-tracks:hover {
  animation-play-state: paused;
}
@keyframes scroll-right {
  100% {
    transform: translateX(0);
  }
  0% {
    transform: translateX(calc(-250px * 9));
  }
}


.slides {
  display: flex;
  align-items: center;
  object-fit: contain;
  padding: 2px;
  perspective: 100px;
}

img {
  transition: 0.6s;
  width: 7%;
  height: 45%;
  margin-left: 10px;
  display: block;
  border-radius: 0.6rem;
}

.minis:hover {
  transform: scale(1.9);
}

/* .sliders::before,
.sliders::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 15px;
  z-index: 1;
} */

.sliders::before {
  left: 0;
  top: 0;
}

.sliders::after {
  right: 0;
  top: 0;
  transform: rotate(180deg);
}

.images {
  width: 70%;
  height: 90%;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 650px) {
  .slides-tracks{
    width: calc(100% * 18);

  }
  .images {
    width: 80%;
    height: 40%;
  }

  .minis:hover {
    transform: scale(1.3);
  }
  .fulls {
    /* margin-top: 78px; */
    width: fit-content;
    height: fit-content;
    /* background: white; */
  }
  .cancles {
    /* margin-top: 40%; */
    position: relative;
    background: black;
    border-radius: 70px;
    /* top: 80px; */
    /* left: 80px; */
  }
}