.slider-container {
  overflow: hidden;
  transition: transform 0.5s ease-out;
  padding:20px;
  margin:14px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  box-shadow: 2px 2px 10px #cbc8c8;
  border-radius:10px;
  align-items: center;

}

.slider-image {
  width: 100%;
  height: 300px;
}

.slider-text {
  margin: 10px 0;
  padding-left:10px;
}
.slider-tit {
  margin-top: 10px;
    align-self:center;
    font-size:25px;
    font-weight:bold;
}

.arrow-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.arrow-button {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size:20px;
  border-radius:25px;
  font-weight:bolder;
  width:50px;
};

.dot-indicators {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    align:center;
    margin-left:30px;
  }
  
  .dot.active {
    background-color: #4CAF50;
  }